<template>
  <component
    :is="component"
    v-if="component"
    ref="subComponent"
    :key="component.__file"
    v-bind="$attrs"
  />
</template>

<script>
import { connectionTypes } from '@/config/connection';
import { routeNames } from '@/config/router/router.config';

export default {
  name: 'Connection',
  data() {
    return {
      component: null,
      componentByParam: {
        [connectionTypes.SPREADSHEET]: 'Spreadsheet',
        [connectionTypes.DATABASE]: 'DbRelated',
        [connectionTypes.DEAR]: 'BaseConnector',
        [connectionTypes.SHOPIFY]: 'BaseConnector',
        [connectionTypes.UNLEASHED]: 'BaseConnector',
        [connectionTypes.PRONTO_XI]: 'BaseConnector',
        [connectionTypes.CUSTOM_API]: 'BaseConnector',
        [connectionTypes.CIN7]: 'BaseConnector',
        [connectionTypes.EXACT]: 'BaseConnector',
        [connectionTypes.SELLERCLOUD]: 'BaseConnector',
        [connectionTypes.BUSINESS_CENTRAL]: 'BaseConnector',
        [connectionTypes.FINALE]: 'BaseConnector',
        [connectionTypes.SPIRE]: 'BaseConnector',
        [connectionTypes.QB_DESKTOP]: 'BaseConnector',
        [connectionTypes.NETSUITE]: 'DbRelated',
        [connectionTypes.SKUBANA]: 'BaseConnector',
        [connectionTypes.DYNAMICS_NAV]: 'DbRelated',
        [connectionTypes.ODOO]: 'DbRelated',
        [connectionTypes.SAP]: 'DbRelated',
        [connectionTypes.FISHBOWL]: 'DbRelated',
        [connectionTypes.DYNAMICS_GP]: 'DbRelated',
        [connectionTypes.QB_ONLINE]: 'BaseConnector'
      }
    };
  },
  methods: {
    updateComponent(param) {
      if (!this.componentByParam[param]) {
        return this.$router.push({
          name: routeNames.DEMAND
        });
      }

      import(`@/views/Connectors/${this.componentByParam[param]}.vue`).then(module => {
        this.component = module.default;
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.updateComponent(to.params.connector));
  },
  beforeRouteUpdate(to, from, next) {
    this.updateComponent(to.params.connector);
    next();
  },
  beforeRouteLeave(to, from, next) {
    const guard = this.$refs?.subComponent?.beforeRouteLeaveGuard;

    if (!guard) {
      return next();
    }

    guard(to, from, next);
  }
};
</script>
